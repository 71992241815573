
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';



import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Sharee from "./components/pages/Sharee";
import Jeans from "./components/pages/Jeans";
import Shirt from "./components/pages/Shirt";
import Churidar from "./components/pages/Churidar";
import Tshirt from "./components/pages/Tshirt";
import Blazer from "./components/pages/Blazer";
import Panjabi from "./components/pages/Panjabi";
import Kids from "./components/pages/Kids";
import Zoom from "./components/pages/Zoom";
import Aboutus from "./components/pages/Aboutus";
import Footer from "./components/inc/Footer";
import Winterpage from './components/pages/Winterpage';

function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/contact" element={<Contact/>}/>
      <Route axact path="/saree" element={<Sharee/>}/>
      <Route axact path="/jeans" element={<Jeans/>}/>
      <Route axact path="/Churidar" element={<Churidar/>}/>
      <Route axact path="/Tshirt" element={<Tshirt/>}/>
      <Route axact path="/Blazer" element={<Blazer/>}/>
      <Route axact path="/Panjabi" element={<Panjabi/>}/>
      <Route axact path="/Shirt" element={<Shirt/>}/>
      <Route axact path="/Kids" element={<Kids/>}/>
      <Route axact path="/Zoom" element={<Zoom/>}/>
      <Route axact path="/Aboutus" element={<Aboutus/>}/>
      <Route axact path="/winter-collection" element={<Winterpage/>}/>

      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
