
import React from "react"
import { Link } from 'react-router-dom';
import logo from "../images/bimanlogo.jpg";

function Navbar() {
  return (
    <>
      <div className="fixed-top">

        <div className="bimanbastralayacolor">
          <div className="album py-0">

            <div className="container">

              <div className="row ">

                <div className="col-md-9" >
                  <div className="d-flex justify-content-between align-items-center">

                    <a className="btn btn-success btn-sm" href="tel: +91 9932517802">Call</a>




                    <marquee behavior="alternate" scrollamount="7"><h4 style={{ color: "maroon" }} className="mt-2"> Welcome To Biman Bastralaya - Since : 2000</h4></marquee>




                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">








                </div>
              </div>
            </div>
          </div>
        </div>


        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
          <div className="container-fluid ">
            <Link to="/">
              <img src={logo} className="img-fluid me-3" width="65" height="20" alt="Logos" />
            </Link>
            <div className="album py-0">

              <div className="container">


                <h6 style={{ color: "maroon" }} className="card-text mb-0"> <b> Since : 2000 </b></h6>

                <p style={{ color: "maroon" }} className="card-text me-5"> <small>Biman Bastralaya</small></p>
              </div>
            </div>


            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" style={{ color: "green" }} className="nav-link active" aria-current="page" href="#"> <b>HOME</b></Link>
                </li>
                <li className="nav-item">
                  <Link to="/Aboutus" style={{ color: "green" }} className="nav-link active" aria-current="page" href="#"> <b>ABOUT US</b></Link>
                </li>
                <li className="nav-item">
                  <Link to="/winter-collection" style={{ color: "green" }} className="nav-link active" aria-current="page" href="#"> <b>WINTER COLLECTION</b></Link>
                </li>

                <li className="nav-item dropdown">
                  <a style={{ color: "green" }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <b> PRODUCTS</b>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/saree" className="dropdown-item" href="#">Saree</Link></li>
                    <li><Link to="/Shirt" className="dropdown-item" href="#">Shirt</Link></li>
                    <li><Link to="/Churidar" className="dropdown-item" href="#">Churidar</Link></li>
                    <li><Link to="/Tshirt" className="dropdown-item" href="#">T-Shirt</Link></li>
                    <li><Link to="/Blazer" className="dropdown-item" href="#">Blazer</Link></li>
                    <li><Link to="/Panjabi" className="dropdown-item" href="#">Panjabi</Link></li>
                    <li><Link to="/Kids" className="dropdown-item" href="#">Kids</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link to="/jeans" className="dropdown-item" href="#">Jeans</Link></li>
                    {/* <li><Link to="/Zoom" className="dropdown-item" href="#">Zoom</Link></li> */}
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/contact" style={{ color: "green" }} className="nav-link active" aria-current="page" href="#"> <b>CONTACT US</b></Link>
                </li>
              </ul>


              <a href="tel: 9932517802" className="btn btn- btn-success">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
              </svg> +91 9932517802*</a>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;