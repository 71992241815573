
import React from "react"
import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider";
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import Products from "../pages/Products";
import ReactPlayer from "react-player";
import Bimansharees from "../images/SHARESBIMAN.jpg";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


import Bimanbastralayashowroomkarimpur from "../images/bimanbastralaya-head.jpg";

import Sharee from "../images/SHAREECOLLECTION.jpg";
import ShareeSbiman from "../images/COLLEC.jpg";
import Sharees from "../images/SHAREECOL.jpg";
import Shareecollection from "../images/SHAREECOLLECTIONPUJA.jpg";
import Bimanbastralayashowrooms from "../images/BIMANMAINPAGEy.jpg";
import weddingcolection from "../images/BIA ADS 2.jpg"
import bibahaads from "../images/BIA AD 2.jpg";

import "./Home.css";
import Winter from "./Winter";

function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (

    <>
      <Navbar />
      <br />

      <ScrollToTop smooth top="100" color="maroon" />
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <h5>Biman Bastralaya</h5>
          </div>
        </div>
      </div>
      <br />
      <img src={Bimanbastralayashowroomkarimpur} className="d-block w-100" alt="Bimanbastralaya" />



      <Products />

      <br />
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
            <div className="card" >
  <img src={weddingcolection} className="card-img-top" alt="weddingcolection"/>
  <div className="card-body">
    <h5 className="card-title">Biman Bastralaya</h5>
    <p className="card-text">Best Wedding Collection</p>
    <a href="tel: 9932517802" className="callnow">Call Now</a>
  </div>
</div>
              </div>
              <div className="col-md-6 mt-3">
              <div className="card" >
  <img src={bibahaads} className="card-img-top" alt="weddingcolection"/>
  <div className="card-body">
  <h5 className="card-title">Biman Bastralaya</h5>
  <p className="card-text">Best Wedding Collection</p>
    <a href="tel: 9932517802" className="callnow">Call Now</a>
  </div>
</div>
</div>
              </div>
              </div>
            </div>
      <div className="album py-4">
        <div className="container">
          <div className="row">
            <div className="videreactjs">
              <ReactPlayer width="100%" height="100%" url="https://www.youtube.com/watch?v=H0c_XB6JoRc" />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="album py-1">
      <div className="container">
        <h1><b>Winter</b> Collection 2025</h1>
        </div>
        </div>
<Winter/>
      <Slider />

      <div className="album py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">


              <div className="radius">
                <img src={Bimanbastralayashowrooms} className="card-img-top" alt="Bimanbastralayashowroom" />
              </div>

              <div className="d-flex justify-content-between align-items-center">


              </div>

            </div>
            <br />
            <div className="col-md-6 mb-3 mt-3" >
              <h1 style={{ color: "Darkblue" }} className="card-text"> <b>Biman Bastralaya</b></h1>
              <h1 style={{ color: "Darkblue" }} className="card-text"> <b>Since: 2000</b></h1>
              <p style={{ color: "Darkblue" }} className="card-text">Best Garments Showroom in Karimpur
              </p>

              <div className="d-flex justify-content-between align-items-center">



              </div>

            </div>






          </div>


        </div>

      </div>

      <hr />
      <div className='radiussharee'>
        <Link to='/saree' target="_blank" rel="noopener noreferrer">
          <img src={Bimansharees} className="d-block w-100" alt="Bimanbastralaya" /></Link>
      </div>
      <hr />
      <br />
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <h1 style={{ color: "maroon" }}><b>Best Saree Collection - BIMAN BASTRALAYA</b></h1>
          </div>
        </div>
      </div>


      <div className="album py-2">
        <div className="container">
          <div className="row">


            <Carousel responsive={responsive}>

              <div className="card border mb-3 me-3" >
                <Link to="/saree">
                  <img className="card-img-top" src={ShareeSbiman} alt="biman_bastralaya" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Sarees</b></h6>
                    <p className="card-text"><small>By Biman Bastralaya </small></p>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <Link to="/saree">
                  <img className="card-img-top" src={Sharees} alt="biman_bastralaya" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Sarees </b></h6>
                    <p className="card-text"><small>By Biman Bastralaya</small></p>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <Link to="/saree">
                  <img className="card-img-top" src={Shareecollection} alt="biman_bastralaya" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Sarees </b></h6>
                    <p className="card-text"><small>By Biman Bastralaya</small></p>
                  </center>
                </div>
              </div>



              <div className="card border mb-3 me-3" >
                <Link to="/saree">
                  <img className="card-img-top" src={Sharee} alt="biman_bastralaya" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Sarees </b></h6>
                    <p className="card-text"><small>By Biman Bastralaya</small></p>
                  </center>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

      </div>
      <br />
      <div className="album py-2">

        <div className="container">

          <div className="row">

            <iframe className="iframe-fluid mb-2" src="https://www.google.com/maps/embed?pb=!4v1690479647357!6m8!1m7!1sZ6g5xmOl_2Z01-ULJYumcA!2m2!1d23.98224995602774!2d88.63313116394505!3f160.65243800200318!4f6.915038183872269!5f0.7820865974627469" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


            <iframe className="iframe-fluid" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Biman%20Bastralay,%20XJJM+R7R,%20Anandapally,%20West%20Bengal%20741152%20+(Biman%20Bastralay,%20XJJM+R7R,%20Anandapally,%20West%20Bengal%20741152)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>


          </div>
        </div>
      </div>

      <br />
      <br />

    </>
  );
}

export default Home;